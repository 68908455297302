<template>
    <v-layout row wrap pa-4>
        <v-flex>
            <w-select
                v-model="selectedTestCaseIndex"
                :items="testCases"
                item-text="case"
                color="primary"
                :label="$t('sa.geds.classement')"
                :loading="testCasesLoading"
            />
            <v-text-field v-model="path" :label="$t('sa.geds.folder')" :readonly="true" />
        </v-flex>
        <v-flex>
            <w-btn flat icon="quiz" :disabled="!selectedTestCaseIndex" :loading="testLoading" @click="test()">{{$t('sa.geds.actions.test')}}</w-btn>
        </v-flex>
    </v-layout>
</template>
<script>
import SuperAdminCatalogTreeStructureModuleGuard from '@/mixins/ModulesGuards/SuperAdmin/SuperAdminCatalogTreeStructureModuleGuard'

export default {
    name: 'DocumentsStructuresVisualisation',
    mixins: [SuperAdminCatalogTreeStructureModuleGuard],
    props: {
        value: {
            required: true,
            type: Number
        }
    },
    data: function () {
		return {
            selectedTestCaseIndex: null,
			testCases: new Array(),
            testCasesLoading: false,
            path: "",
            testLoading: false,
        }
    },
    watch: {
		value: {
			handler: function () {
				this.path = ''
			}
		}
	},
    created: function () {
        this.getTestCases()
    },
    methods: {
        getTestCases: function () {
            this.testCasesLoading = true
            this.service.getTestCases().then(testCases => {
					this.testCases = testCases
            })
            .finally(() => {
                this.testCasesLoading = false
            })
        },
        test: function () {
            this.testLoading = true
            this.path = ""
            let selectedTestCase = this.testCases.filter(tc => tc.id === this.selectedTestCaseIndex)[0]
            return this.service.getBestCatalogFolderFromFlags(this.value, selectedTestCase.catalog_flags.map(flag => flag.name)).then(result => {
                this.path = result ? result.location + result.name : 'A classer (se créera automatiquement)'
            }).finally(() => {
                this.testLoading = false
            })
        }
    }
}

</script>
