// ============================================================================
// SuperAdminCatalogTreeStructureApi
// ------------------
// 
// ============================================================================

// -------
// Imports
// -------
import { Backend } from '@/plugins/axios/Configurator'
import URLS from '@/apis/BackEndAPIEndpoints'

import { RequiredArguments } from '@/helpers/methods'

// -------
// Exports
// -------
export default {
    sa: {
        catalogFlags: {
            read: function (params = null, doCancelPreviousRequest = false, doCancelAllOtherRequests = false) {
                return Backend.GET(URLS.api.sa.catalogFlags.uri, [], params, doCancelPreviousRequest, doCancelAllOtherRequests)
            }
        },
        catalogThemes: {
            read: function (params = null, doCancelPreviousRequest = false, doCancelAllOtherRequests = false) {
                return Backend.GET(URLS.api.sa.catalogTheme.uri, [], params, doCancelPreviousRequest, doCancelAllOtherRequests)
            }
        },
        catalogTreeStructure: {
            create: function (data = null, doCancelPreviousRequest = false, doCancelAllOtherRequests = false) {
				return Backend.POST(URLS.api.sa.catalogTreeStructure.uri, [], {}, data, doCancelPreviousRequest, doCancelAllOtherRequests)
			},
            read: function (catalogTreeStructureId, params = null, doCancelPreviousRequest = false, doCancelAllOtherRequests = false) {
				RequiredArguments({ catalogTreeStructureId })
				return Backend.GET(URLS.api.sa.catalogTreeStructure.uri, [catalogTreeStructureId], params, doCancelPreviousRequest, doCancelAllOtherRequests)
			},
			update: function (catalogTreeStructureId, data = null, doCancelPreviousRequest = false, doCancelAllOtherRequests = false) {
				RequiredArguments({ catalogTreeStructureId })
				return Backend.PATCH(URLS.api.sa.catalogTreeStructure.uri, [catalogTreeStructureId], {}, data, doCancelPreviousRequest, doCancelAllOtherRequests)
			},
            delete: function (catalogTreeStructureId, doCancelPreviousRequest = false, doCancelAllOtherRequests = false) {
                RequiredArguments({ catalogTreeStructureId })
				return Backend.DELETE(URLS.api.sa.catalogTreeStructure.uri, [catalogTreeStructureId], {}, doCancelPreviousRequest, doCancelAllOtherRequests)
            },
            test: function (catalogTreeStructureId, catalogFlags, doCancelPreviousRequest = false, doCancelAllOtherRequests = false) {
                RequiredArguments({ catalogTreeStructureId })
				return Backend.GET(URLS.api.sa.catalogTreeStructure.test.uri, [catalogTreeStructureId], {catalogFlags}, doCancelPreviousRequest, doCancelAllOtherRequests)
            },

            catalogFolder: {
                create: function (catalogTreeStructureId, data, doCancelPreviousRequest = false, doCancelAllOtherRequests = false) {
                    RequiredArguments({ catalogTreeStructureId })
                    return Backend.POST(URLS.api.sa.catalogTreeStructure.catalogFolder.uri, [catalogTreeStructureId], {}, data, doCancelPreviousRequest, doCancelAllOtherRequests)
                },
                read: function (catalogTreeStructureId, catalogFolderId,  params = null, doCancelPreviousRequest = false, doCancelAllOtherRequests = false) {
                    RequiredArguments({ catalogTreeStructureId, catalogFolderId })
                    return Backend.GET(URLS.api.sa.catalogTreeStructure.catalogFolder.uri, [catalogTreeStructureId, catalogFolderId], params, doCancelPreviousRequest, doCancelAllOtherRequests)
                },
                update: function (catalogTreeStructureId, catalogFolderId, data, doCancelPreviousRequest = false, doCancelAllOtherRequests = false) {
                    RequiredArguments({ catalogTreeStructureId, catalogFolderId })
                    return Backend.PATCH(URLS.api.sa.catalogTreeStructure.catalogFolder.uri, [catalogTreeStructureId, catalogFolderId], {}, data, doCancelPreviousRequest, doCancelAllOtherRequests)
                },
                delete: function (catalogTreeStructureId, catalogFolderId, doCancelPreviousRequest = false, doCancelAllOtherRequests = false) {
                    RequiredArguments({ catalogTreeStructureId, catalogFolderId })
                    return Backend.DELETE(URLS.api.sa.catalogTreeStructure.catalogFolder.uri, [catalogTreeStructureId, catalogFolderId], {},doCancelPreviousRequest, doCancelAllOtherRequests)
                },
            },
            treeStructures: {
                sync: function (catalogTreeStructureId, data = null, doCancelPreviousRequest = false, doCancelAllOtherRequests = false) {
                    RequiredArguments({catalogTreeStructureId})
                    return Backend.POST(URLS.api.sa.catalogTreeStructure.treeStructure.uri, [catalogTreeStructureId, 'synchronize'], {}, data, doCancelPreviousRequest, doCancelAllOtherRequests)
                }
            }
        },
        catalogTreeStructures: {
            read: function (params = null, doCancelPreviousRequest = false, doCancelAllOtherRequests = false) {
				return Backend.GET(URLS.api.sa.catalogTreeStructure.uri, [], params, doCancelPreviousRequest, doCancelAllOtherRequests)
			}
        },
        catalogTreeStructureTestCases: {
            read: function (params = null, doCancelPreviousRequest = false, doCancelAllOtherRequests = false) {
                return Backend.GET(URLS.api.sa.catalogTreeStructureTestCases.uri, [], params, doCancelPreviousRequest, doCancelAllOtherRequests)
            }
        },
        catalogTreeTools: {
            read: function (params = null, doCancelPreviousRequest = false, doCancelAllOtherRequests = false) {
				return Backend.GET(URLS.api.sa.catalogTreeTool.uri, [], params, doCancelPreviousRequest, doCancelAllOtherRequests)
			}
        },
        durations: {
            read: function (params = null, doCancelPreviousRequest = false, doCancelAllOtherRequests = false) {
				return Backend.GET(URLS.api.sa.duration.uri, [], params, doCancelPreviousRequest, doCancelAllOtherRequests)
			}
        }
    }
}
