// ============================================================================
// SuperAdminCatalogTreeStructureService
// -----------------------
// 
// ============================================================================

// -------
// Imports
// -------
import API from '@/apis/SuperAdminCatalogTreeStructureApi'

// ---------
// Internals
// ---------
const Private = {
	requests: {
        catalogFlags: {
            read: API.sa.catalogFlags.read
        },
        catalogThemes: {
            read: API.sa.catalogThemes.read
        },
        catalogTreeStructure: {
            create: API.sa.catalogTreeStructure.create,
            read: API.sa.catalogTreeStructure.read,
            update: API.sa.catalogTreeStructure.update,
            delete: API.sa.catalogTreeStructure.delete,
            test: API.sa.catalogTreeStructure.test,

            catalogFolder: {
                create: API.sa.catalogTreeStructure.catalogFolder.create,
                read: API.sa.catalogTreeStructure.catalogFolder.read,
                update: API.sa.catalogTreeStructure.catalogFolder.update,
                delete: API.sa.catalogTreeStructure.catalogFolder.delete
            },
            treeStructures: {
                sync: API.sa.catalogTreeStructure.treeStructures.sync
            },

        },
        catalogTreeStructures: {
            read: API.sa.catalogTreeStructures.read
        },
        catalogTreeStructureTestCases: {
            read: API.sa.catalogTreeStructureTestCases.read
        },
        catalogTreeTools: {
            read: API.sa.catalogTreeTools.read
        },
        durations: {
            read: API.sa.durations.read
        }
    },
	services: {
        catalogFlags: {
            read: function () {
                return Private.requests.catalogFlags.read()
            }
        },
        catalogThemes: {
            read: function () {
                return Private.requests.catalogThemes.read()
            }
        },
        catalogTreeStructure: {
            create: function (data) {
                return Private.requests.catalogTreeStructure.create(data)
            },
            read: function (catalogTreeStructureId)  {
                return Private.requests.catalogTreeStructure.read(catalogTreeStructureId)
            },
            update: function (catalogTreeStructureId, data)  {
                return Private.requests.catalogTreeStructure.update(catalogTreeStructureId, data)
            },
            delete: function (catalogTreeStructureId)  {
                return Private.requests.catalogTreeStructure.delete(catalogTreeStructureId)
            },
            test: function (catalogTreeStructureId, catalogFlags) {
                return Private.requests.catalogTreeStructure.test(catalogTreeStructureId, catalogFlags)
            },

            catalogFolder: {
                create: function (catalogTreeStructureId, data) {
                    return Private.requests.catalogTreeStructure.catalogFolder.create(catalogTreeStructureId, data)
                },
                read: function (catalogTreeStructureId, catalogFolderId, params) {
                    return Private.requests.catalogTreeStructure.catalogFolder.read(catalogTreeStructureId, catalogFolderId, params)
                },
                update: function (catalogTreeStructureId, catalogFolderId, data) {
                    return Private.requests.catalogTreeStructure.catalogFolder.update(catalogTreeStructureId, catalogFolderId, data)
                },
                delete: function (catalogTreeStructureId, catalogFolderId) {
                    return Private.requests.catalogTreeStructure.catalogFolder.delete(catalogTreeStructureId, catalogFolderId)
                }
            },
            treeStructures: {
                sync: function (catalogTreeStructureId) {
                    return Private.requests.catalogTreeStructure.treeStructures.sync(catalogTreeStructureId)
                }
            }
        },
        catalogTreeStructures: {
            read: function () {
                return Private.requests.catalogTreeStructures.read()
            }
        },
        catalogTreeStructureTestCases: {
            read: function () {
                return Private.requests.catalogTreeStructureTestCases.read()
            }
        },
        catalogTreeTools: {
            read: function () {
                return Private.requests.catalogTreeTools.read()
            }
        },
        durations: {
            read: function (params = null) {
                return Private.requests.durations.read(params)
            }
        }
	}
}

// -------
// Exports
// -------
export default {
    createCatalogFolder: Private.services.catalogTreeStructure.catalogFolder.create,
    getCatalogFolder: Private.services.catalogTreeStructure.catalogFolder.read,
    updateCatalogFolder: Private.services.catalogTreeStructure.catalogFolder.update,
    deleteCatalogFolder: Private.services.catalogTreeStructure.catalogFolder.delete,

    listCatalogThemes: Private.services.catalogThemes.read,

    createCatalogTreeStructure: Private.services.catalogTreeStructure.create,
    getCatalogTreeStructure: Private.services.catalogTreeStructure.read,
	listCatalogTreeStructures: Private.services.catalogTreeStructures.read,
    updateCatalogTreeStructure: Private.services.catalogTreeStructure.update,
    deleteCatalogTreeStructure: Private.services.catalogTreeStructure.delete,

    listDurations: Private.services.durations.read,

    listCatalogFlags: Private.services.catalogFlags.read,

    listTools: Private.services.catalogTreeTools.read,

    synchronizeTreeStructures: Private.services.catalogTreeStructure.treeStructures.sync,

    getTestCases: Private.services.catalogTreeStructureTestCases.read,
    getBestCatalogFolderFromFlags: Private.services.catalogTreeStructure.test
}
